import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { buyNFT } from "../utils/web3";
import { NFTModel } from "../models/nft-model";
import {
  getNFTs,
  getNFTsWithCategory,
  getTokenIdByAvatar,
  saveNFTLog,
} from "../networking/networkCalls";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import { useWeb3ModalAccount, useWeb3Modal } from "@web3modal/ethers5/react";
import { bigIntReplacer } from "../utils/utils";
import { NFTCategoryModel } from "../models/nft-category-model";

export default function HomeNFT() {
  const [loading, setLoading] = useState<boolean>(true);
  const [dataList, setDataList] = useState<NFTCategoryModel[]>([]);
  const [activeTab, setActiveTab] = useState<string>("");
  const [carouselActive, setCarouselActive] = useState<number>(0);

  const [snackBarState, setSnackBarState] = useState<{
    show: boolean;
    msg: string;
    severity: AlertColor;
  }>({
    show: false,
    msg: "",
    severity: "success",
  });

  const { isConnected } = useWeb3ModalAccount();
  const { open } = useWeb3Modal();
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      const res = await getNFTsWithCategory();

      if (res && res.data.success) {
        const { data } = res.data;

        if (Object.keys(data).length !== 0) {
          const dataList: NFTCategoryModel[] = [];
          for (const [key, value] of Object.entries(data)) {
            if (value) {
              const subDataList: NFTModel[] = [];
              for (const d of value as Array<any>) {
                subDataList.push(d as NFTModel);
              }

              dataList.push({ category: key, nfts: subDataList });
            }
          }

          if (dataList.length > 0) {
            setActiveTab(dataList[0].category);
          }

          setDataList(dataList);
        }

        // if ((data as Array<any>).length > 0) {
        //   const dataList: NFTModel[] = [];
        //   for (const d of data as Array<any>) {
        //     dataList.push(d as NFTModel);
        //   }

        //   setDataList(dataList);
        // }
      }

      setLoading(false);
    };

    getData();
  }, []);

  const getTokenIdByAvatarId = useCallback(async (avatarId: number) => {
    const res = await getTokenIdByAvatar({ avatar_id: avatarId });

    if (res && res.status === 200 && res.data && res.data.success) {
      return res.data.tokenId;
    }

    return undefined;
  }, []);

  const saveNFTLogData = useCallback(
    async (data: {
      avatar_id: number;
      token_id: number;
      blockchain_txreceipt: string;
    }) => {
      const res = await saveNFTLog(data);

      return res && res.status === 201 && res.data && res.data.success;
    },
    []
  );

  const onBuyHandler = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    data: NFTModel
  ) => {
    // if (!isConnected) {
    //   open();
    //   return;
    // }

    // setSnackBarState({
    //   show: true,
    //   msg: "Processing request",
    //   severity: "info",
    // });

    // const tokenIdToBeBought: number | undefined = await getTokenIdByAvatarId(
    //   data.id
    // );

    // if (!tokenIdToBeBought) {
    //   setSnackBarState({
    //     show: true,
    //     msg: "NFT not available",
    //     severity: "error",
    //   });
    //   return;
    // }

    // const { success, error, txReceipt } = await buyNFT(
    //   tokenIdToBeBought,
    //   data.required_metasetgo_token
    // );

    // if (!success) {
    //   setSnackBarState({
    //     show: true,
    //     msg: error.msg || "Something went wrong, please try again later",
    //     severity: "error",
    //   });
    //   return;
    // }

    // // save log in db
    // const logRes: boolean = await saveNFTLogData({
    //   avatar_id: data.id,
    //   token_id: tokenIdToBeBought,
    //   blockchain_txreceipt: JSON.stringify(txReceipt, bigIntReplacer, 2),
    // });

    // if (!logRes) {
    //   // error saving log
    //   setSnackBarState({
    //     show: true,
    //     msg: "Something went wrong, please try again later",
    //     severity: "error",
    //   });
    //   return;
    // }

    // // successfully bought nft
    // setSnackBarState({
    //   show: true,
    //   msg: "NFT has been bought",
    //   severity: "success",
    // });

    // // remove purchased nft from list
    // let nftCategories = [...dataList];
    // nftCategories = nftCategories.map((v, i) => {
    //   let nft = v.nfts.find((val) => val.id === data.id);

    //   if (nft) {
    //     const updatedNfts = v.nfts.filter((val) => val.id !== nft.id);
    //     v.nfts = updatedNfts;
    //   }

    //   return v;
    // });

    // setDataList(nftCategories);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarState({ ...snackBarState, show: false });
  };
  return (
    <>
      <Snackbar
        open={snackBarState.show}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarState.severity}
          sx={{ width: "100%" }}
        >
          {snackBarState.msg}
        </Alert>
      </Snackbar>
      <section className="characters orangeBG" id="NFT">
        <div className="seperatorOrangeTop" />
        <div className="container pb-3 justify-content-center text-center">
          <h2
            className="mb-5 animated"
            style={{ fontFamily: "'Titan One', cursive" }}
            data-animation="fadeInUp"
            data-animation-delay={150}
          >
            NFT CHARACTERS
          </h2>
          <ul
            className="nav nav-pills mb-3 justify-content-evenly animated"
            id="pills-tab"
            role="tablist"
            data-animation="fadeInUp"
            data-animation-delay={150}
          >
            {!loading &&
              dataList.length > 0 &&
              dataList.map((v, i) => (
                <li key={i} className="nav-item" role="presentation">
                  <Link
                    id={`${v.category}-tab`}
                    className={`series1bg btn-series${
                      activeTab === v.category ? " active" : ""
                    }`}
                    type="button"
                    role="tab"
                    data-bs-target={`#${v.category}`}
                    onClick={(e) => {
                      setActiveTab(v.category);
                      setCarouselActive(0);
                    }}
                    to={""}
                  >
                    <h3>{v.category}</h3>
                  </Link>
                </li>
              ))}
          </ul>
          <div
            className="tab-content w-50 mx-auto w-sm-100"
            id="pills-tabContent"
          >
            {dataList.map((v, i) => {
              return (
                <div
                  key={i}
                  className={`tab-pane fade show${
                    activeTab === v.category ? " active" : ""
                  }`}
                  id={v.category}
                  role="tabpanel"
                  aria-labelledby={`${v.category}-tab`}
                >
                  <div
                    id="nftcarousel"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <ol className="carousel-indicators">
                      {v.nfts.map((val, index) => (
                        <li
                          key={index}
                          data-target="#nftcarousel"
                          data-slide-to={index}
                          className={`${
                            carouselActive === index ? "active" : ""
                          }`}
                        />
                      ))}
                    </ol>
                    <div className="carousel-inner">
                      {v.nfts.map((val, index) => (
                        <div
                          key={index}
                          className={`carousel-item${
                            carouselActive === index ? " active" : ""
                          }`}
                        >
                          <img
                            className=" d-block"
                            src={val.thumbnail}
                            height={500}
                            width={350}
                            style={{ alignSelf: "center" }}
                            alt={""}
                          />
                          <div className="character">
                            {/* <h3>{val.categoryName}</h3> */}
                            <Link
                              className="btn-buy"
                              to="/nft-marketplace"
                              onClick={(e) => onBuyHandler(e, val)}
                            >
                              BUY NOW
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                    <button
                      className="carousel-control-prev carousel-control-prev2"
                      data-bs-target="#nftcarousel"
                      type="button"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      >
                        <i className="fa fab fa-solid fa-arrow-left" />
                      </span>
                      <span className="sr-only">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next carousel-control-next2"
                      data-bs-target="#nftcarousel"
                      type="button"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      >
                        <i className="fa fab fa-solid fa-arrow-right" />
                      </span>
                      <span className="sr-only">Next</span>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="seperatorOrangeBottom" />
      </section>
      <section className="background" />
    </>
  );
}
