import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
   <>
   <footer className="blueBG">
  <div className="seperator" />
  <div className="container text-center">
    <div className="row align-items-center">
      <div className="col-12">
        <img src="assets/images/RUMBLEGO-logo.png" width="300px" alt="RumbleGO Logo" />
      </div>
      <div className="col-sm-12 col-md-3">
        <h3>Community</h3>
        <Link to="https://t.me/MetaSetGo" target="_blank" rel="noopener noreferrer" tabIndex={0}>
          <i className="fab fa-brands fa-telegram " />
        </Link>
      </div>
      <div className="col-sm-12 col-md-3">
        <h3>Socials</h3>
        <Link to="https://twitter.com/RumbleGO_" target="_blank" rel="noopener noreferrer" tabIndex={0}>
          <i className="fab fa-twitter btn-icon-inner" />
        </Link>
      </div>
      <div className="col-sm-12 col-md-3">
        <h3>NFT</h3>
        <Link to="https://zunaverse.io/creators/0xed7f8833a7e0a7c46c3711b9249606f08dfe63fa?tab=collections" target="_blank" rel="noopener noreferrer" tabIndex={0}><i className="fab fa-brands fa-microsoft" />
        </Link>
      </div>
      <div className="col-sm-12 col-md-3">
        <h3>Email</h3>
        <Link to="mailto:support@rumblego.io" target="_blank" rel="noopener noreferrer"><i className="fa fab fa-solid fa-envelope fa-2xl" /></Link>
      </div>
    </div>
    <div className="row mt-4">
      <div className="col">
        <h4>Brought to you by</h4>
        <img src="assets/images/MsgoNewLogoLight2.png" className="img-fluid mb-2" width="100px" alt="MetaSetGO Logo" />
        <p><Link to='#'>Terms of Service</Link></p>
        <p className="text-white">© 2023 RumbleGO - All Rights Reserved</p>
      </div>
    </div>
  </div>
</footer>
   </>
  )
}
