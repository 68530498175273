import React, { useCallback, useEffect, useState } from "react";
import Footer from "../comman_component/Footer";
import Header from "../comman_component/Header";
import {
  getNFTs,
  getTokenIdByAvatar,
  saveNFTLog,
  updateProfile,
} from "../networking/networkCalls";
import { NFTModel } from "../models/nft-model";
import { Alert, AlertColor, LinearProgress, Snackbar } from "@mui/material";
import { buyNFT, setWeb3Provider } from "../utils/web3";
import {
  useWeb3ModalAccount,
  useWeb3Modal,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { bigIntReplacer } from "../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store";
import { useNavigate } from "react-router-dom";

export default function NFTMarketplace() {
  // @ts-ignore
  const isLoggedIn: boolean = useSelector((state) => state.auth.isLoggedIn);
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [dataList, setDataList] = useState<NFTModel[]>([]);

  const [snackBarState, setSnackBarState] = useState<{
    show: boolean;
    msg: string;
    severity: AlertColor;
  }>({
    show: false,
    msg: "",
    severity: "success",
  });

  const dispatch = useDispatch<AppDispatch>();

  const { isConnected, address } = useWeb3ModalAccount();
  const { open } = useWeb3Modal();
  const { walletProvider } = useWeb3ModalProvider();

  useEffect(() => {
    const updateWalletAddress = async () => {
      await updateProfile(address);
    };

    if (isConnected && address) {
      // set provider in ethers
      setWeb3Provider(walletProvider);
      // update address in db
      updateWalletAddress();
    } else {
      setWeb3Provider(undefined);
    }
  }, [isConnected, address, walletProvider, dispatch]);

  useEffect(() => {
    const getData = async () => {
      const res = await getNFTs();

      if (res && res.data.success) {
        const { data } = res.data;

        if ((data as Array<any>).length > 0) {
          const dataList: NFTModel[] = [];
          for (const d of data as Array<any>) {
            dataList.push(d as NFTModel);
          }

          setDataList(dataList);
        }
      }

      setLoading(false);
    };

    getData();
  }, []);

  const getTokenIdByAvatarId = useCallback(async (avatarId: number) => {
    const res = await getTokenIdByAvatar({ avatar_id: avatarId });

    if (res && res.status === 200 && res.data && res.data.success) {
      return res.data.tokenId;
    }

    return undefined;
  }, []);

  const saveNFTLogData = useCallback(
    async (data: {
      avatar_id: number;
      token_id: number;
      blockchain_txreceipt: string;
    }) => {
      const res = await saveNFTLog(data);

      return res && res.status === 201 && res.data && res.data.success;
    },
    []
  );

  const onBuyHandler = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: NFTModel
  ) => {
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }

    if (data.hasBought === 1) {
      setSnackBarState({
        show: true,
        msg: "You have already purchased this NFT",
        severity: "error",
      });
      return;
    }

    if (!data.hasUnclaimedTokens) {
      setSnackBarState({
        show: true,
        msg: "This NFT is unavailable",
        severity: "error",
      });
      return;
    }

    if (!isConnected) {
      open();
      return;
    }

    setSnackBarState({
      show: true,
      msg: "Processing request",
      severity: "info",
    });

    const tokenIdToBeBought: number | undefined = await getTokenIdByAvatarId(
      data.id
    );

    if (!tokenIdToBeBought) {
      setSnackBarState({
        show: true,
        msg: "NFT not available",
        severity: "error",
      });
      return;
    }

    const { success, error, txReceipt } = await buyNFT(
      tokenIdToBeBought,
      data.required_metasetgo_token
    );

    if (!success) {
      setSnackBarState({
        show: true,
        msg: error.msg || "Something went wrong, please try again later",
        severity: "error",
      });
      return;
    }

    // save log in db
    const logRes: boolean = await saveNFTLogData({
      avatar_id: data.id,
      token_id: tokenIdToBeBought,
      blockchain_txreceipt: JSON.stringify(txReceipt, bigIntReplacer, 2),
    });

    if (!logRes) {
      // error saving log
      setSnackBarState({
        show: true,
        msg: "Something went wrong, please try again later",
        severity: "error",
      });
      return;
    }

    // successfully bought nft
    setSnackBarState({
      show: true,
      msg: "NFT has been bought",
      severity: "success",
    });

    // remove purchased nft from list
    let nfts = [...dataList];
    nfts = nfts.filter((v, i) => v.id !== data.id);

    setDataList(nfts);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarState({ ...snackBarState, show: false });
  };

  return (
    <>
      <Header />
      <Snackbar
        open={snackBarState.show}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarState.severity}
          sx={{ width: "100%" }}
        >
          {snackBarState.msg}
        </Alert>
      </Snackbar>
      <main>
        <section className="py-5 text-center  blueBG">
          <div className="row py-lg-5">
            <div className="col-lg-6 col-md-8 mx-auto">
              <h1 className="fw-light">GO Cards</h1>
              <p className="text-shadow">
                Purchase your favourite GO Card character below and equip them
                as your RumbleGO in game character!
              </p>
            </div>
          </div>
        </section>

        {loading && <LinearProgress />}

        {!loading && dataList.length > 0 && (
          <div className="album py-5 bg-body-tertiary">
            <div className="container album">
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                {dataList.map((v, i) => {
                  return (
                    <div className="col" key={v.id}>
                      <div className="card shadow blueBG rounded-4">
                        <img src={v.thumbnail} alt="images" />
                        <div className="card-body">
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <p className="text-shadow nftPrice">
                              {v.required_metasetgo_token} Metasetgo Token
                            </p>
                            <div className="btn-group">
                              <button
                                type="button"
                                className="btn btn-pri btn-lg px-4 btn-shadow animated"
                                data-animation="zoomInUp"
                                onClick={(e) => onBuyHandler(e, v)}
                              >
                                <h2 className="text-shadow">BUY NOW</h2>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </main>

      <Footer />
    </>
  );
}
