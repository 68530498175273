import { BigNumber, ethers, utils } from "ethers";
import stakingContractABI from "../constants/staking-contract-abi.json";
import tokenContractABI from "../constants/token-contract-abi.json";
import tokenEscrowContractABI from "../constants/token-escrow-contract-abi.json";
import { numberToDecimal } from "./utils";
import store from "../store";
import { ExternalProvider } from "@ethersproject/providers";

let provider: ethers.providers.Web3Provider = undefined;
let signer: ethers.providers.JsonRpcSigner = undefined;
let stakingContract: ethers.Contract = undefined;
let tokenContract: ethers.Contract = undefined;
let tokenEscrowContract: ethers.Contract = undefined;

const setWeb3Provider = (web3Provider: ExternalProvider | undefined) => {
  if (!web3Provider) {
    // console.log("INSIDE NULL CASE PROVIDER");
    provider = undefined;
    return;
  }
  // console.log("INSIDE NOT NULL CASE PROVIDER");
  provider = new ethers.providers.Web3Provider(web3Provider);
};

const getProviderSignerContract = async () => {
  // if (provider === undefined) {
  // if (window.ethereum != null && window.ethereum !== undefined) {
  //   provider = new ethers.providers.Web3Provider(window.ethereum);
  // }
  // }

  // const state = store.getState();
  // const externalProvider: ExternalProvider = state.web3Provider.provider;

  // console.log("externalProvider --> ", externalProvider);
  // console.log("externalProvider type --> ", typeof externalProvider);

  // if (externalProvider) {
  //   provider = new ethers.providers.Web3Provider(externalProvider);
  // }

  if (provider !== undefined) {
    signer = provider.getSigner();
    stakingContract = new ethers.Contract(
      process.env.REACT_APP_STAKING_SMART_CONTRACT_ADDRESS,
      stakingContractABI,
      signer
    );

    tokenContract = new ethers.Contract(
      process.env.REACT_APP_TOKEN_SMART_CONTRACT_ADDRESS,
      tokenContractABI,
      signer
    );

    tokenEscrowContract = new ethers.Contract(
      process.env.REACT_APP_TOKEN_ESCROW_SMART_CONTRACT_ADDRESS,
      tokenEscrowContractABI,
      signer
    );
  }

  return {
    provider,
    signer,
    stakingContract,
    tokenContract,
    tokenEscrowContract,
  };
};

const approveAmount = async (amount: string, spenderAddress: string) => {
  try {
    const { tokenContract, signer } = await getProviderSignerContract();

    const allowance: ethers.BigNumber = await tokenContract.allowance(
      signer.getAddress(),
      spenderAddress
    );

    // console.log("allowance --> ", allowance);

    // console.log("amount --> ", amount);

    // Convert the amount to Wei using parseUnits
    const amountInWei: ethers.BigNumber = ethers.utils.parseUnits(
      Number.parseFloat(amount).toFixed(9),
      9
    );

    // console.log("amountInWei --> ", amountInWei);

    if (allowance.gte(amountInWei)) {
      return { success: true, amountInWei };
    }

    // Get the user's token balance
    const userBalance: ethers.BigNumber = await tokenContract.balanceOf(
      await signer.getAddress()
    );

    // console.log("signer.getAddress() --> ", await signer.getAddress());
    // console.log("userBalance --> ", userBalance);

    // Compare the balance with the intended approval amount
    if (userBalance.lt(amountInWei)) {
      return {
        success: false,
        error: {
          code: "INSUFFICIENT_FUND",
          msg: "Wallet does not have enough MSG tokens",
        },
      };
    }

    // Proceed with the approval
    const tx: ethers.providers.TransactionResponse =
      await tokenContract.approve(spenderAddress, amountInWei);

    const txReceipt: ethers.providers.TransactionReceipt = await tx.wait();

    // Check status of transaction
    if (txReceipt.status !== 1) {
      throw new Error("Something went wrong, please try again later");
    }

    return { success: true, error: undefined, amountInWei };
  } catch (error) {
    // console.log(`ERROR :: approveAmount :: ${error}`);
    return { success: false, error, amountInWei: undefined };
  }
};

const buyNFT = async (tokenId: number, msgToken: number) => {
  const response = await approveAmount(
    `${msgToken}`,
    process.env.REACT_APP_STAKING_SMART_CONTRACT_ADDRESS
  );

  if (!response.success) {
    return { ...response, txReceipt: undefined };
  }

  try {
    const { stakingContract } = await getProviderSignerContract();

    // Call the buyStakedNFT method
    const tx: ethers.providers.TransactionResponse =
      await stakingContract.buyStakedNFT(
        process.env.REACT_APP_NFT_SMART_CONTRACT_ADDRESS,
        tokenId,
        response.amountInWei
      );

    const txReceipt: ethers.providers.TransactionReceipt = await tx.wait();

    // Check the status of the transaction
    if (txReceipt.status !== 1) {
      throw new Error("Something went wrong with buying NFT");
    }

    return { success: true, error: undefined, txReceipt };
  } catch (error) {
    // console.log(`ERROR :: buyNFT :: ${error}`);
    return { success: false, error, txReceipt: undefined };
  }
};

const getMyMsgoTokenBalance = async () => {
  try {
    const { tokenContract } = await getProviderSignerContract();

    // Call the balanceOf function on the contract
    const balance = await tokenContract.balanceOf(signer.getAddress());

    // console.log(balance);
    // console.log(balance.toString());

    // Return the balance
    return balance.toString();
  } catch (error) {
    console.error("Error fetching MSGO balance:", error);
    return null;
  }
};

const receiveInGameCoin = async (
  msgoTokenUnitPrice: number,
  inGameCoinUnitPrice: number,
  msgoTokenQuantity: number
) => {
  // console.log(
  //   "IN receiveInGameCoin : msgoTokenUnitPrice :::::: ",
  //   msgoTokenUnitPrice,
  //   Math.round(msgoTokenUnitPrice * 1e9)
  // );
  // console.log(
  //   "IN receiveInGameCoin : inGameCoinUnitPrice :::::: ",
  //   inGameCoinUnitPrice
  // );
  // console.log(
  //   "IN receiveInGameCoin : msgoTokenQuantity :::::: ",
  //   msgoTokenQuantity
  // );

  const response = await approveAmount(
    `${msgoTokenQuantity}`,
    process.env.REACT_APP_TOKEN_ESCROW_SMART_CONTRACT_ADDRESS
  );

  if (!response.success) {
    return { ...response, txReceipt: undefined };
  }

  try {
    const { tokenEscrowContract, signer } = await getProviderSignerContract();

    const tx = await tokenEscrowContract.populateTransaction.receiveInGameCoins(
      Math.round(numberToDecimal(msgoTokenUnitPrice, 9)), // converting fractional value to 9 decimal places integer value
      Math.round(numberToDecimal(inGameCoinUnitPrice, 9)), // converting fractional value to 9 decimal places integer value
      Math.round(numberToDecimal(msgoTokenQuantity, 9)) // converting fractional value to 9 decimal places integer value ex: if msgoTokenQuantity is 1 then on blochchain, it should be 1000000000
    );

    // Try to estimate gas for the transaction
    try {
      const estimatedGas = await signer.estimateGas(tx);

      // Add some buffer to the estimated gas
      const gasLimit = estimatedGas.mul(120).div(100); // Increase by 20%

      // Set the gas limit in the transaction object
      tx.gasLimit = gasLimit;

      // Send the actual transaction
      const txResponse = await signer.sendTransaction(tx);
      const txReceipt = await txResponse.wait();

      // Check the status of the transaction
      if (txReceipt.status !== 1) {
        throw new Error("Something went wrong");
      }

      return { success: true, error: undefined, txReceipt };
    } catch (estimateGasError) {
      // Set a manual gas limit
      tx.gasLimit = ethers.BigNumber.from(300000); // Set an appropriate gas limit

      // Send the actual transaction
      const txResponse = await signer.sendTransaction(tx);
      const txReceipt = await txResponse.wait();

      // Check the status of the transaction
      if (txReceipt.status !== 1) {
        throw new Error("Something went wrong");
      }

      return { success: true, error: undefined, txReceipt };
    }
  } catch (error) {
    // console.log(`ERROR :: receiveInGameCoin :: ${error}`);

    return { success: false, error, txReceipt: undefined };
  }
};

const receiveMsgoToken = async (
  msgoTokenUnitPrice: number,
  inGameCoinUnitPrice: number,
  inGameCoinQuantity: number
) => {
  try {
    const { tokenEscrowContract, signer } = await getProviderSignerContract();

    // since every gainer has to pay 5% tax of how much msgo token they get,
    // we are decreasing inGameCoinUnitPrice by 5%
    // thus the final quantity of msgo token will become 5% less in smart contract calculation
    inGameCoinUnitPrice *= 0.95;

    // Create a new transaction object without sending it
    const tx = await tokenEscrowContract.populateTransaction.receiveMsgoTokens(
      Math.round(numberToDecimal(msgoTokenUnitPrice, 9)),
      Math.round(numberToDecimal(inGameCoinUnitPrice, 9)),
      // ethers.utils.parseUnits(
      //   Number.parseFloat(`${msgoTokenUnitPrice}`).toFixed(9),
      //   9
      // ),
      // ethers.utils.parseUnits(
      //   Number.parseFloat(`${inGameCoinUnitPrice}`).toFixed(9),
      //   9
      // ),
      Math.round(numberToDecimal(inGameCoinQuantity, 9)) // we need 9 decimal places for blockchain to calculate the msgoToken to be received
    );

    // Try to estimate gas for the transaction
    try {
      const estimatedGas = await signer.estimateGas(tx);

      // Add some buffer to the estimated gas
      const gasLimit = estimatedGas.mul(120).div(100); // Increase by 20%

      // Set the gas limit in the transaction object
      tx.gasLimit = gasLimit;

      // Send the actual transaction
      const txResponse = await signer.sendTransaction(tx);
      const txReceipt = await txResponse.wait();

      // Check the status of the transaction
      if (txReceipt.status !== 1) {
        throw new Error("Something went wrong");
      }

      return { success: true, error: undefined, txReceipt };
    } catch (estimateGasError) {
      // Set a manual gas limit
      tx.gasLimit = ethers.BigNumber.from(300000); // Set an appropriate gas limit

      // Send the actual transaction
      const txResponse = await signer.sendTransaction(tx);
      const txReceipt = await txResponse.wait();

      // Check the status of the transaction
      if (txReceipt.status !== 1) {
        throw new Error("Something went wrong");
      }

      return { success: true, error: undefined, txReceipt };
    }
  } catch (error) {
    // console.log(`ERROR :: receiveMsgoToken :: ${error}`);
    return { success: false, error, txReceipt: undefined };
  }
};

export {
  buyNFT,
  getMyMsgoTokenBalance,
  receiveInGameCoin,
  receiveMsgoToken,
  setWeb3Provider,
};

// ipfs://QmQgLbfDBUtwR5UEfFw5ZfM8NnRLGdLYw6YmrN8JupRkZH/{id}.json

// NFT CONTRACT Address : 0x7fa18D52B677789eEa728A8137E3063EaFAC870F
// Token Smart Contract Address : 0x46c08856562A9F8aD5EBED03fFe054E69D1d3b08
// Staking Contract Address : 0x4cA97dc42F3Cc00B1Ee516df512f42989B7c7041
// Token Escrow Smart Contract Address : 0xe33c198c798E66395489849988AfFd9A9144e68d

// In the token contract, we have kept 9 decimal places
// that means 1000000000 tokens is equal to 1 msgo token

// Steps

// 1) Deploy NFT smart contract
// 2) Deploy Staking smart contract
// 3) Deploy Token smart contract
// 4) Deploy Token Escrow smart contract
// 5) Call approve method of deployed Token smart contract to approve deployed Token Escrow Contract to be able to transfer tokens (This will be required for "In Game Coin" to "Msgo Token" Swap)
// 6) Call setApprovalForAll method of deployed NFT smart contract to allow deployed Staking Contract to be able to transfer nfts
// 7) Call approve method of deployed Token smart contract to approve deployed Staking Contract to be able to transfer tokens from buyer’s account to platform owner’s account or to any account (this method is better to be called on react.js frontend before every NFT purchase)
// 8) In the Staking smart contract, call skakeMultiple or stake method to stake the nfts
// 9) In the Token Escrow smart contract, call stakeMsgoTokens to stake the tokens (This will be required for "In Game Coin" to "Msgo Token" Swap, so that Token Escrow smart contract can transfer msgo token to user's account)
